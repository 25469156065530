<script setup>
import { reactive, onMounted, watch } from "vue";
import { useUserStore } from "../../stores/user";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import gravatarUrl from "gravatar-url";
import useUsers from "../../hooks/useUsers";
import { useRoute, useRouter, definePage } from "vue-router/auto";

definePage({
  meta: {
    requiresManagerAuth: true,
  },
});

const {
  updatePage,
  users,
  usersLoading,
  adminDialog,
  fetchUsers,
  fetchTimezones,
  timezones,
  createAdmin,
} = useUsers();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const newAdmin = reactive({
  name: null,
  email: null,
  local_timezone: "America/New_York",
});
const localState = reactive({
  page: parseInt(route.query.page) || 1,
  searchText: null,
  filters: [
    {
      column: "superadmin",
      condition: "eq",
      value: true,
    },
  ],
  sort: "id",
});

onMounted(() => {
  setPageTitle("Manage Admin Users");
  fetchUsers(localState);
  fetchTimezones();
});

watch(
  () => localState.page,
  (newVal, _) => {
    fetchUsers(localState);
  }
);

watch(
  () => localState.searchText,
  (newVal, _) => {
    if (localState.searchText.length > 0) {
      let existingFilter = localState.filters.find((f) => f.column == "name");
      if (existingFilter) {
        existingFilter.value = newVal;
      } else {
        localState.filters.push({
          column: "name",
          condition: "like",
          value: newVal,
        });
      }
    } else {
      localState.filters = null;
    }
    fetchUsers(localState);
  }
);
</script>

<template>
  <div v-if="userStore.user && users">
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-card-title>
          {{ pageTitle }}
        </v-card-title>
        <v-card-subtitle>Use this section to manage users. </v-card-subtitle>
      </div>
      <div class="d-flex align-center mr-4">
        <v-btn
          prepend-icon="mdi-account"
          color="primary"
          @click="() => (adminDialog = !adminDialog)"
          >New Admin</v-btn
        >
      </div>
    </div>
    <v-card class="mx-4 px-4 mt-4">
      <div class="mt-4">
        <v-text-field
          v-model="searchText"
          variant="outlined"
          color="indigo-lighten-2"
          prepend-inner-icon="mdi-text-box-search-outline"
          label="Search by name"
          flat
        ></v-text-field>
        <v-table>
          <thead class="">
            <tr>
              <th class="text-left">User</th>
              <th class="text-left">Created At</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody v-if="usersLoading">
            <tr>
              <td colspan="4" class="text-center">
                <v-progress-circular
                  size="30"
                  indeterminate
                  color="blue-darken-2"
                ></v-progress-circular>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!usersLoading && users.data.length == 0">
            <tr>
              <td colspan="4" class="text-center">No users found.</td>
            </tr>
          </tbody>
          <tbody v-if="!usersLoading && users && users.data.length > 0" class="">
            <tr
              v-for="user in users.data"
              :key="user.id"
              style="cursor: pointer"
              @dblclick="router.push(`/users/${user.id}`)"
            >
              <td>
                <v-row class="py-2">
                  <v-col cols="auto" class="d-flex align-center"> {{ user.id }}</v-col>
                  <v-col>
                    <div class="d-flex align-center w-100">
                      <v-avatar
                        :image="
                          gravatarUrl(user.email, {
                            size: 32,
                            default: 'retro',
                          })
                        "
                        size="24"
                        color="surface-variant"
                        class="mr-2"
                      ></v-avatar
                      >{{ user.name }}
                    </div>
                    <span class="text-indigo-lighten-2 text-caption">{{
                      user.email
                    }}</span>
                  </v-col>
                </v-row>
              </td>
              <td>{{ shortDateWithTimeTz(user.created_at) }}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      density="comfortable"
                      icon="mdi-dots-horizontal"
                      elevation="0"
                      v-bind="props"
                    ></v-btn>
                  </template>

                  <v-list>
                    <!-- <v-list-item :to="`/users/${user.id}`">
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item :to="`/users/${user.id}/edit`">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item>
                      <v-list-item-title class="text-error">Delete</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card>
    <div class="text-center mt-4" v-if="users && users.total_pages > 1">
      <v-pagination
        v-model="page"
        :length="users.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>

  <v-dialog v-model="adminDialog" persistent width="500">
    <v-card>
      <v-card-title class="text-h5">Create a new superadmin </v-card-title>
      <v-card-text>
        <v-text-field v-model="newAdmin.name" label="Name"></v-text-field>
        <v-text-field
          v-model="newAdmin.email"
          label="E-mail (append +admin if e-mail exists in connect)"
        ></v-text-field>
        <v-autocomplete
          v-model="newAdmin.local_timezone"
          :items="timezones"
          label="Local Timezone"
          v-if="timezones"
        ></v-autocomplete>
        <v-text-field
          label="Password is randomly generated and e-mailed"
          disabled
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" variant="text" @click="adminDialog = false"> Cancel </v-btn>
        <v-btn color="primary" variant="flat" @click="createAdmin(newAdmin)">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
